(function () {
  'use strict';
  function validate(action = {}) {
    let returnable;
    if (
      angular.isObject(action.word) &&
      angular.isString(action.word.paragraph) &&
      angular.isString(action.word.word)
    ) {
      returnable = true;
    }
    return returnable;
  }
  function reducer(action, $firedux) {
    return $firedux
      .ref('content/findWord')
      .child(angular.isString(action.wordId) ?
         action.wordId : $firedux.var('UID'))
      .set({
        paragraph: action.word.paragraph,
        word: action.word.word
      });
  }
  angular
    .module('content.find-word.update.reducer', [])
    .run(run);
  function run($firedux) {
    $firedux.reducer({
      trigger: 'CONTENT.FIND_WORD.UPDATE',
      reducer: function (action) {
        return validate(action) ?
          reducer(action, $firedux) :
          Promise.reject('VALIDATION ERROR');
      }
    });
  }
}());
