(function () {
  'use strict';
  class Controller {
    constructor($firedux) {
      this.$firedux = $firedux;
    }
    $approve({amount, method, payer, userId}, requestId) {
      const ONE_YEAR = 1000 * 60 * 60 * 24 * 365,
          UNIT_PRICE = 100000 * 1.19;
      // Create license
      this.$firedux.ref('/licenses').child(requestId).set({
        timestamp: {
          '.sv': 'timestamp'
        },
        balancePaid: amount * UNIT_PRICE,
        slots: amount,
        method,
        payer,
        isPaid: true,
        expirationDate: Date.now() + ONE_YEAR
      })
      // Remove request
      .then(() => this.$firedux.ref('/licenseRequests').child(requestId).remove())
      // Get user institutionId
      .then(() => this.$firedux.ref('/users').child(userId).child('institutionId').once('value').then(snap => snap.val()))
      // Add license to institution
      .then((institutionId) => this.$firedux.ref('/institutions').child(institutionId).child('licenseId').set(requestId));
    }
    $revoke({slots, method, payer, userId}, requestId) {
      userId = userId || this.$firedux.auth.uid;
      // Create request
      this.$firedux.ref('licenseRequests').child(requestId).set({
        timestamp: {
          '.sv': 'timestamp'
        },
        userId,
        amount: slots,
        method,
        payer
      })
      // Remove license
      .then(() => this.$firedux.ref('licenses').child(requestId).remove())
      // Get user institutionId
      .then(() => this.$firedux.ref('/users').child(userId).child('institutionId').once('value').then(snap => snap.val()))
      // Remove license from institution
      .then((institutionId) => this.$firedux.ref('/institutions').child(institutionId).child('licenseId').set(requestId));
    }
  }
  angular
    .module('licenses', [
      'licenses.add'
    ])
    .component('licensesEl', {
      controller: Controller,
      templateUrl: 'root/licenses/licenses.route.html'
    })
    .config(function ($stateProvider) {
      $stateProvider
        .state('licenses', {
          abstract: false,
          url: '/licenses',
          template: '<licenses-el/>'
        });
    });
}());
