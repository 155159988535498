(function () {
  'use strict';
  class Controller {
    constructor($mdSidenav) {
      this.$mdSidenav = $mdSidenav;
    }
    $toggleSidenav() {
      this.$mdSidenav(this.sidenav).toggle();
    }
  }
  angular
    .module('root.nav', [])
    .component('rootNav', {
      controller: Controller,
      templateUrl: 'root/_components/nav/nav-root.comp.html',
      bindings: {
        sidenav: '@'
      }
    });
}());
