(function () {
  'use strict';
  class Controller {
    constructor($firedux, $mdSidenav) {
      this.$firedux = $firedux;
      this.$mdSidenav = $mdSidenav;
    }
    $toggleSidenav() {
      this.$mdSidenav(this.sidenav).toggle();
    }
    $logout() {
      this.$firedux.logout();
    }
  }
  angular
    .module('root.sidenav', [])
    .component('rootSidenav', {
      controller: Controller,
      templateUrl: 'root/_components/sidenav/sidenav-root.comp.html',
      bindings: {
        sidenav: '@'
      }
    });
}());
