(function () {
  'use strict';
  class Controller {
    constructor($firedux, $window) {
      this.$firedux = $firedux;
      this.$uid = this.$firedux.var('UID');
      this._ = $window._;
      this.PrismicJS = $window.PrismicJS;
      this.PrismicDOM = $window.PrismicDOM;
      this.getPrismic();
    }
    getPrismic() {
      const _ = this._;
      var apiEndpoint = 'https://intelligere.prismic.io/api/v2';
      this.PrismicJS.getApi(apiEndpoint).then(api => {
        return Promise.all([
          this.getPage(api, 1),
          this.getPage(api, 2),
          this.getPage(api, 3),
          this.getPage(api, 4),
          this.getPage(api, 5),
          this.getPage(api, 6),
          this.getPage(api, 7),
          this.getPage(api, 8),
          this.getPage(api, 9),
          this.getPage(api, 10)
        ]).then(_.flatten)
        .then(results => _(results).map(({data: {title}, tags, ['last_publication_date']: date}) => ({title: this.PrismicDOM.RichText.asText(title), tags, date})))
        .then(results => {
          this.$results = results;
        });
      });
    }
    getPage(api, page) {
      return api.query([
        this.PrismicJS.Predicates.at('document.type', 'article')
      ], {
        pageSize: 100,
        page
      }).then(({results}) => results);
    }
  }
  angular
    .module('content.articles.list', [])
    .component('contentArticlesList', {
      controller: Controller,
      templateUrl: 'root/content/articles/list/list-articles-content.route.html'
    })
    .config(function ($stateProvider) {
      $stateProvider
        .state('content.articles.list', {
          abstract: false,
          url: '/',
          template: '<content-articles-list/>'
        });
    });
}());
