(function () {
  'use strict';

  angular
    .module('admin', [
      'app.routes',
      'app.firedux',
      'app.themes',
      'root',
      'md.data.table'
    ]);
}());
