(function () {
  'use strict';
  function validateWord(word) {
    return (/^[a-záéíóúü]*$/g).test(word);
  }
  function validate(action = {}) {
    let returnable;
    if (
      angular.isString(action.words)
    ) {
      returnable = true;
    }
    return returnable;
  }
  function parseWords(words) {
    let wordList = words.toLowerCase().split(' '),
        returnable = {};
    angular.forEach(wordList, word => {
      if (validateWord(word)) {
        returnable[word] = word;
      }
    });
    return returnable;
  }
  function reducer(action, $firedux) {
    return $firedux
      .ref('content/words')
      .update(parseWords(action.words));
  }
  angular
    .module('content.words.add.reducer', [])
    .run(run);
  function run($firedux) {
    $firedux.reducer({
      trigger: 'CONTENT.WORDS.ADD',
      reducer: function (action) {
        return validate(action) ?
          reducer(action, $firedux) :
          Promise.reject('VALIDATION ERROR');
      }
    });
  }
}());
