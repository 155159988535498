(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('content.words.add', [
      'content.words.add.reducer'
    ])
    .component('contentWordsAdd', {
      controller: Controller,
      templateUrl: 'root/content/words/_actions/add/add-words-content.action.html',
      bindings: {
        words: '<',
        then: '&',
        catch: '&'
      }
    });
}());
