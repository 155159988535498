(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('content.words.remove', [
      'content.words.remove.reducer'
    ])
    .component('contentWordsRemove', {
      controller: Controller,
      templateUrl: 'root/content/words/_actions/remove/remove-words-content.action.html',
      bindings: {
        word: '<',
        then: '&',
        catch: '&'
      }
    });
}());
