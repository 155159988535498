(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('lessons.triangles.module', [
      'lessons.triangles.module.intro',
      'lessons.triangles.module.triangle',
      'lessons.triangles.module.test',
      'lessons.triangles.module.summary'
    ])
    .component('lessonsTrianglesModule', {
      controller: Controller,
      templateUrl: 'root/lessons/triangles/_components/module/module-triangles-lessons.comp.html',
      bindings: {
        period: '<',
        cycles: '<',
        total: '<'
      }
    });
}());
