(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('content.articles.detail', [
      'content.articles.detail.questions'
    ])
    .component('contentArticlesDetail', {
      controller: Controller,
      templateUrl: 'root/content/articles/detail/detail-articles-content.route.html'
    })
    .config(function ($stateProvider) {
      $stateProvider
        .state('content.articles.detail', {
          abstract: false,
          url: '/:articleId',
          template: '<content-articles-detail/>'
        });
    });
}());
