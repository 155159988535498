(function () {
  'use strict';
  class Controller {
    constructor($window) {
      this._ = $window._;
    }
    $array(obj) {
      return this._(obj).toArray();
    }
  }
  angular
    .module('content.words', [
      'content.words.add',
      'content.words.remove'
    ])
    .component('contentWords', {
      controller: Controller,
      templateUrl: 'root/content/words/words-content.route.html'
    })
    .config(function ($stateProvider) {
      $stateProvider
        .state('content.words', {
          abstract: false,
          url: '/words',
          template: '<content-words/>'
        });
    });
}());
