(function () {
  'use strict';
  function config($mdThemingProvider) {
    let blackMap = $mdThemingProvider.extendPalette('grey', {
      800: '#113b4e',
      900: '#0a2b3c',
      contrastDefaultColor: 'dark'
    });
    $mdThemingProvider.theme('default')
      .primaryPalette('light-blue', {
        default: '100',
        'hue-1': '100'
      })
      .accentPalette('light-blue', {
        default: '400'
      });
    $mdThemingProvider.theme('blue')
      .primaryPalette('light-blue', {
        default: '700'
      });
    $mdThemingProvider.definePalette('blackPalette', blackMap);
    $mdThemingProvider.theme('black')
      .primaryPalette('blackPalette', {
        default: '900',
        'hue-1': '800',
        'hue-2': '50'
      });
  }
  angular
    .module('app.themes', [])
    .config(config);
}());
