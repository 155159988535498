(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('content.find-word.remove', [
      'content.find-word.remove.reducer'
    ])
    .component('contentFindWordRemove', {
      controller: Controller,
      templateUrl: 'root/content/find-word/_actions/remove/remove-find-word-content.action.html',
      bindings: {
        wordId: '<',
        then: '&',
        catch: '&'
      }
    });
}());
