(function () {
  'use strict';
  function validate(action = {}) {
    let returnable;
    if (
      angular.isString(action.articleId) &&
      angular.isObject(action.article) &&
      angular.isObject(action.article.content) &&
      angular.isString(action.article.content.title) &&
      angular.isString(action.article.content.body)
    ) {
      returnable = true;
    }
    return returnable;
  }
  function reducer(action, $firedux) {
    return $firedux
      .ref('content/articles')
      .child(action.articleId)
      .set({
        content: {
          title: action.article.content.title,
          body: action.article.content.body
        },
        topics: action.article.topics || {},
        questions: action.article.questions || {}
      });
  }
  angular
    .module('content.articles.save.reducer', [])
    .run(run);
  function run($firedux) {
    $firedux.reducer({
      trigger: 'CONTENT.ARTICLES.SAVE',
      reducer: function (action) {
        return validate(action) ?
          reducer(action, $firedux) :
          Promise.reject('VALIDATION ERROR');
      }
    });
  }
}());
