(function () {
  'use strict';

  angular
    .module('app.routes', [
      'ui.router'
    ])
    .config(config);

  function config($urlRouterProvider, $locationProvider, $windowProvider) {
    $urlRouterProvider.otherwise('/content');
    $locationProvider.hashPrefix('');
    if ($windowProvider.$get().location.protocol === 'https:') {
      $locationProvider.html5Mode(true);
    }
  }
}());
