(function () {
  'use strict';
  angular
    .module('lessons', [
      'lessons.triangles',
      'lessons.article'
    ])
    .config(function ($stateProvider) {
      $stateProvider
      .state('lessons', {
        abstract: true,
        url: '/lessons',
        template: '<ui-view/>'
      });
    });
}());
