(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('content.find-word', [
      'content.find-word.remove',
      'content.find-word.update'
    ])
    .component('contentFindWord', {
      controller: Controller,
      templateUrl: 'root/content/find-word/find-word-content.route.html'
    })
    .config(function ($stateProvider) {
      $stateProvider
        .state('content.find-word', {
          abstract: false,
          url: '/find-word',
          template: '<content-find-word/>'
        });
    });
}());
