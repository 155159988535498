(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('content.articles.saveQuestions', [
      'content.articles.saveQuestions.reducer'
    ])
    .component('contentArticlesSaveQuestions', {
      controller: Controller,
      templateUrl: 'root/content/articles/_actions/saveQuestions/saveQuestions-articles-content.action.html',
      bindings: {
        articleId: '<',
        questions: '<',
        then: '&',
        catch: '&'
      }
    });
}());
