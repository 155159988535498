(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('root', [
      // Components
      'root.nav',
      'root.sidenav',
      // Routes
      'content',
      'login',
      'lessons',
      'users',
      'licenses',
      'institutions'
    ])
    .component('rootEl', {
      controller: Controller,
      templateUrl: 'root/root.comp.html'
    });
}());
