(function () {
  'use strict';
  angular
    .module('content', [
      'content.articles',
      'content.words',
      'content.find-word'
    ])
    .config(function ($stateProvider) {
      $stateProvider
      .state('content', {
        abstract: true,
        url: '/content',
        template: '<ui-view/>'
      });
    });
}());
