(function () {
  'use strict';
  class Controller {
    constructor($firedux, $mdMenu, $http) {
      this.$firedux = $firedux;
      this.$mdMenu = $mdMenu;
      this.$http = $http;
    }
    $requestUser({email, password, displayName}) {
      return this.$http.post('https://us-central1-intelligere-94e9b.cloudfunctions.net/accountRequest', {
        email,
        password,
        displayName
      });
    }
    $deleteUser(uid) {
      return this.$firedux.ref(`users/${uid}/DELETE`).set(true);
    }
    $activateUser(uid) {
      return this.$firedux.ref(`users/${uid}`).update({isMember: true});
    }
    $deactivateUser(uid) {
      return this.$firedux.ref(`users/${uid}`).update({isMember: null, institutionId: null});
    }
  }
  angular
    .module('users', [])
    .component('usersEl', {
      controller: Controller,
      templateUrl: 'root/users/users.route.html'
    })
    .config(function ($stateProvider) {
      $stateProvider
        .state('users', {
          abstract: false,
          url: '/users',
          template: '<users-el/>'
        });
    });
}());
