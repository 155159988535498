(function () {
  'use strict';
  function validate(action = {}) {
    let returnable;
    if (
      angular.isString(action.wordId)
    ) {
      returnable = true;
    }
    return returnable;
  }
  function reducer(action, $firedux) {
    return $firedux
      .ref('content/findWord')
      .child(action.wordId)
      .set(null);
  }
  angular
    .module('content.find-word.remove.reducer', [])
    .run(run);
  function run($firedux) {
    $firedux.reducer({
      trigger: 'CONTENT.FIND_WORD.REMOVE',
      reducer: function (action) {
        return validate(action) ?
          reducer(action, $firedux) :
          Promise.reject('VALIDATION ERROR');
      }
    });
  }
}());
