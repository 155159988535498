(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('lessons.triangles.module.summary', [])
    .component('lessonsTrianglesModuleSummary', {
      controller: Controller,
      templateUrl: 'root/lessons/triangles/_components/module/summary/summary-module-triangles-lessons.comp.html',
      bindings: {
        result: '<',
        then: '&'
      }
    });
}());
