(function () {
  'use strict';
  angular
    .module('content.articles', [
      // Actions
      'content.articles.save',
      'content.articles.saveQuestions',
      // Routes
      'content.articles.list',
      'content.articles.detail'
    ])
    .config(function ($stateProvider) {
      $stateProvider
        .state('content.articles', {
          abstract: true,
          url: '/articles',
          template: '<ui-view/>'
        });
    });
}());
