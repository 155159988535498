(function () {
  'use strict';
  class Controller {
    constructor($firedux, $http, $window) {
      this.$firedux = $firedux;
      this.$http = $http;
      this.$window = $window;
      this._ = $window._;
    }
    $addLicense(license, institutionId) {
      this.$firedux.ref(`institutions/${institutionId}/license/`).set(license);
      this.license = {};
    }
    $revokeLicense(institutionId) {
      this.$firedux.ref(`institutions/${institutionId}/license/`).set(null);
      this.license = {};
    }
    $createInstitution(institution) {
      this.$http.post('https://us-central1-intelligere-94e9b.cloudfunctions.net/createInstitution', institution);
      this.institution = {};
    }
    $deleteInstitution({key: institutionId, adminId}) {
      this.$firedux.ref(`institutions/${institutionId}`).remove().then(() =>
        this.$firedux.ref(`users/${adminId}/institutionId`).remove());
    }
    $goToInstitution(institutionId) {
      this.$window.open(`https://groups.intelligere.co/${institutionId}/groups`);
    }
  }
  angular
    .module('institutions', [])
    .component('institutionsEl', {
      controller: Controller,
      templateUrl: 'root/institutions/institutions.route.html'
    })
    .config(function ($stateProvider) {
      $stateProvider
        .state('institutions', {
          abstract: false,
          url: '/institutions',
          template: '<institutions-el/>'
        });
    });
}());
