(function () {
  'use strict';
  class Controller {
    constructor() {}
    $log(obj) {
      console.log(obj);
    }
  }
  angular
    .module('content.articles.detail.questions', [])
    .component('articlesDetailQuestions', {
      controller: Controller,
      templateUrl: 'root/content/articles/detail/_components/questions/questions-detail-articles.comp.html',
      bindings: {
        articleId: '<'
      }
    });
}());
