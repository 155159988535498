(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('lessons.article', [
      'lessons.article.module'
    ])
    .component('lessonsArticle', {
      controller: Controller,
      templateUrl: 'root/lessons/article/article-lessons.route.html'
    })
    .config(function ($stateProvider) {
      $stateProvider
        .state('lessons.article', {
          abstract: false,
          url: '/article',
          template: '<lessons-article/>'
        });
    });
}());
