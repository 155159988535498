(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('lessons.article.module', [])
    .component('lessonsArticleModule', {
      controller: Controller,
      templateUrl: 'root/lessons/article/_components/module/module-article-lessons.comp.html',
      bindings: {}
    });
}());
