(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('lessons.triangles', [
      'lessons.triangles.module'
    ])
    .component('lessonsTriangles', {
      controller: Controller,
      templateUrl: 'root/lessons/triangles/triangles-lessons.route.html'
    })
    .config(function ($stateProvider) {
      $stateProvider
        .state('lessons.triangles', {
          abstract: false,
          url: '/triangles',
          template: '<lessons-triangles/>'
        });
    });
}());
