(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('lessons.triangles.module.intro', [])
    .component('lessonsTrianglesModuleIntro', {
      controller: Controller,
      templateUrl: 'root/lessons/triangles/_components/module/intro/intro-module-triangles-lessons.comp.html',
      bindings: {
        then: '&'
      }
    });
}());
