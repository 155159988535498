(function () {
  'use strict';
  class Controller {
    constructor($window) {
      this._ = $window._;
    }
  }
  angular
    .module('licenses.add', [])
    .component('licensesAdd', {
      controller: Controller,
      templateUrl: 'root/licenses/_components/add/add-licenses.comp.html',
      bindings: {
        then: '&'
      }
    });
}());
